









































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CosmopolTextField extends Vue {
  @Prop({ required: false, default: null, type: [String, Number] })
  private value: string | number;
  @Prop({ required: false, default: true, type: Boolean })
  private hideDetails: boolean;
  @Prop({ required: false, default: false, type: Boolean })
  private disabled: boolean;
  @Prop({ required: false, default: false, type: Boolean })
  private clearable: boolean;
  @Prop({ required: false, default: false, type: Boolean })
  private isPassword: boolean;
  @Prop({ required: false, default: false, type: Boolean })
  private isSearch: boolean;
  @Prop({ required: false, default: null, type: String })
  private iconName: string;
  @Prop({ required: false, default: null, type: String })
  private label: string;
  @Prop({ required: false, default: null, type: String })
  private placeholder: string;

  private textTimeout = null;
  private localeValue = null;
  private showPassword = false;

  private created(): void {
    this.localeValue = this.value;
  }

  private onInput(val: string | number): void {
    if (this.textTimeout) {
      clearTimeout(this.textTimeout);
    }
    this.textTimeout = setTimeout(() => {
      this.localeValue = val;
      this.$emit("on-input", val);
    }, 200);
  }

  onClear(): void {
    this.localeValue = null;
  }
}
